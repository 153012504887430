import React from "react"
import "../style/about.css"
import Layout from "../components/layout"

const aboutPage = () => {
  return (
    <Layout>
      <br /><br /><br /><br />
      <img src="../Images/lx.png" className="lx-img" />
      <div className="container pl-5 pr-5 pt-5">
        {/* ASPIRE ABOUT */}
        <div className="row pb-5">
          <div className="col-md-12 text-center ">
            <p className="pb-4"><img src="../Images/asp.png" alt="Aspire" className="img-about" /></p>
            <h4 className="aspireblue">WINGS OF CHANGE - THE ASPIRE SERIES</h4>
            <p className="mob-justify lh-2">Aspire Aurum by Sumadhura is a collection of real value homes in the heart of the IT boom belt in Bengaluru. Aspire Aurum offers the benefits of a prime location, incredibly affordable prices and an enviable list of amenities that will put more expensive projects in the shade. It is the perfect foundation for ambitious young Indians to put down their roots and prosper. </p>
          </div>
        </div>
      </div>
      {/* Banner Row */}
      <div className="banner">
        <div className="row">
          <img src="../Images/xabout.png" className="x-img" />
          <img src="../Images/banner.png" className="banner-img" />
        </div>
        {/* Description */}
        <div className="row bg-aspireblue">
          <div className="container pl-5 pr-5 text-center">
            <p className="text-white pt-5">A desire to Deliver Without Compromise</p>
            <p className="text-white mob-justify lh-2">The Aspire series of homes has been designed to meet the aspiration and the non-compromise attitude of young India. The Aspire Series strives to deliver real value affordable housing, because when it comes to owning a home, nobody should compromise on</p>             <div className="row pt-4 pb-5 text-left">
              <div className="col-md-3 mb-4">
                <img src="../Images/loc.png" className="img-loc" alt="Location" />
                <span className="text-white pl-3">Location</span>
              </div>
              <div className="col-md-3 mb-4">
                <img src="../Images/pool.png" className="img-spc" alt="Location" />
                <span className="text-white pl-3">Amenities</span>
              </div>
              <div className="col-md-3 mb-4">
                <img src="../Images/thumb.png" className="img-spc" alt="Location" />
                <span className="text-white pl-3">Quality of Construction</span>
              </div>
              <div className="col-md-3">
                <img src="../Images/clock.png" className="img-spc" alt="Location" />
                <span className="text-white pl-3">Timeline of Delivery</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="aspire-red"></div>

      {/* OUR PHILOSOPHY */}
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center pt-3 pb-3">
            <h4 className="aspireblue">OUR PHILOSOPHY</h4>
            <img src="../Images/phi.png" alt="Philosophy" style={{ width: "150px" }} />
            <p className="mob-justify pl-4 pr-4 lh-2">Sumadhura Infracon Pvt. Ltd. is one of the prominent residential and commercial real estate developers in Bangalore & Hyderabad. One of the most admired and trusted brands, Sumadhura has been growing from strength to strength over the past three decades marking its presence over the skylines of Bengaluru and Hyderabad. Sumadhura so far has delivered over 8 million sft of projects while approximately 20 million sft of projects are under construction and planning stages. From being prominently a residential developer, Sumadhura has expanded its horizons into Commercial, Coliving, Plotted Development and other real estate verticals.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center pt-3 pb-3">
            <h4 className="aspireblue">OUR PURPOSE</h4>
            <img src="../Images/dot.PNG" alt="Purpose" style={{ width: "150px" }} />
            <p className="pl-4 pr-4">To Deliver Happiness</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center pt-3 pb-3">
            <h4 className="aspireblue">OUR VISION</h4>
            <img src="../Images/vis.png" alt="Vision" style={{ width: "150px" }} />
            <p className="mob-justify pl-4 pr-4 lh-2">To be a trusted global real estate brand creating a melodic harmony of customer’s happiness,
environmental preservation, and society’s progress.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 pt-3 pb-3 text-center">
            <h4 className="aspireblue">OUR MISSION</h4>
            <img src="../Images/mis.PNG" alt="Mission" style={{ width: "150px" }} />
            <p className="mob-justify pl-4 pr-4 lh-2">We are on a mission to serve families by enhancing their quality of life through our core principle
            of Q.U.E.S.T which is to constantly be in the pursuit of excellence and maximising customer
            happiness. While doing this, we provide a rewarding and challenging environment to our
            employees and value to our stakeholders.Our Q.U.E.S.T defined:</p>
            <p className="mob-justify pr-3">
              <ul className="footer-ul">
                <li>Q – Quality – Be the brand that raises the benchmark of the Quality of Life for our customers.</li>
                <li>U – Unify – Create life spaces that unify the melody between our customer’s life and our nature’s needs.</li>
                <li>E – Engage – Be the extended family of our customers by always being there, hearing them out fully and serving them with a smile.</li>
                <li>S – Sustainability – Build Spaces that makes Mother Earth Smile.</li>
                <li>T – Technology – Optimise Quality of Construction and Experience of Customers with use of cutting-edge Technologies.</li>
              </ul>
            </p>
            <img src="../Images/lr.png" alt="Left Cross" className="x-lr" />
          </div>
        </div>
      </div>
      <div className="row bg-aspiregray">
        <div className="container">
          <div className="col-md-12 pb-4 pt-4">
            <h2 className="aspireblue text-left text-Montserrat-2 pl-4 pt-4 pb-4">OUR MANAGEMENT</h2>
            <p className="mob-justify  pb-5 lh-2 pl-4 pr-4">Sumadhura Infracon Pvt. Ltd. is achieving milestones under the dynamic leadership of Mr. Madhusudhan. G.
            The Aspire Series is a brand for ambitious young Indians from Sumadhura Infracon Pvt Ltd. Sumadhura’s ventures have, since inception, distinguished themselves through their qualitative ideas, uncompromising standards and a rationalized cost to benefit. The concrete values endorsed by the company have endured through decades and as a result, many prestigious projects are shining testaments to Sumadhura’s unwavering commitment to delivering what is promised. This is the reason that it has won the hearts and the loyalty of its clients and the appreciation of its visitors.</p>
            <div id="carouselExampleIndicators" className="carousel slide z-9 pb-5" data-ride="carousel"  data-interval="99999999">
              <ol className="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
              </ol>
              <div className="carousel-inner  cus-ab">
                <div className="carousel-item active">
                  <div className="row bg-white">
                    <div className="col-md-3">
                      <img className="m-img" src="../Images/ms.jpg" alt="M1" /><br />
                    </div><div className="col-md-1"></div>
                    <div className="col-md-8">
                      <p className="text-justify pt-4 lh-2"> Madhusudhan G., a first-generation entrepreneur is a well-known thought-leader with over two
                      decades of experience in the real estate industry. He founded Sumadhura Group, one of South
                      India’s leading real estate companies in 1995. Under his dynamic stewardship, Sumadhura
                      Group has achieved significant milestones, growing at an unprecedented pace with a turnover
                      of over 5 billion rupees.<br /><br />
                      Built on his values of self-belief and work ethics, Sumadhura Group as on date, has completed
                      46 projects, which includes 42 world-class residential and 4 commercial projects with more than
                      4000 customers. Madhusudhan, with his vast industry knowledge and his hands-on approach,
                      he is able to guide the company in delivering of complex large-scale projects with utmost
                      dedication and passion.<br /><br />
                      He instils a positive work environment, through his motivation and mentorship. He is an MBA in
                      Finance from Osmania University, India and an alumnus of IIM-Bengaluru. He is an ardent
                      reader, traveler and sportsperson.</p>
                      <p className="text-left"><b>- Chairman</b></p>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row bg-white">
                    <div className="col-md-3">
                      <img className="m-img" src="../Images/r2.jpg" alt="M1" /><br />
                    </div><div className="col-md-1"></div>
                    <div className="col-md-8">
                      <p className="text-justify pt-4 lh-2"> Ramarao Kalakuntala, Vice-chairman of Sumadhura Group, is been associated with the
                      organization since its inception.His vision for socio-economic growth and opportunity to
                      provide employment, are the key drivers that motivated him to enter the real estate
                      industry. He is known for his strategic advice and council, be it identifying best locations
                      for projects or procuring construction materials.</p>
                      <p className="text-left"><b>- Vice Chairman</b></p>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row bg-white">
                    <div className="col-md-3">
                      <img className="m-img" src="../Images/bk.jpg" alt="M1" /><br />
                    </div><div className="col-md-1"></div>
                    <div className="col-md-8">
                      <p className="text-justify pt-4 lh-2">Bharat Kumar Kandukuri, is one of the directors of Sumadhura Group and is
                      responsible in overseeing company’s rapid expansion and diversification.<br /><br />
                      With two decades of work in the real estate, he has developed expertise in managing
                      and executing quality projects on time. Being a committed, passionate and hard-working
                      person, he always encourages the team to be better every day and appreciates every
                      individual’s work. He is a true innovator and a farsighted man, who excels in bringing
                      the best practices around customer relations in business. He is a commerce graduate
                      from Osmania University and an alumnus of IIM-Bengaluru. Apart from his passion of
                      building sustainable homes, his interests include sports, social causes, cultural activities, travelling and art.</p>
                      <p className="text-left"><b>- Director, Operations</b></p>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row bg-white">
                    <div className="col-md-3">
                      <img className="m-img" src="../Images/nk.jpg" alt="M1" /><br />
                    </div><div className="col-md-1"></div>
                    <div className="col-md-8">
                      <p className="text-justify pt-4 lh-2">Naveen Kumar Gunda is currently engaged in Research and Development for improving construction method
                      and delivering better products. He has been a member of the board of the company
                      since inception. He entered the real estate industry with a passion to build dream
                      abodes for the homebuyers. A fitness and a health enthusiast, Naveen is also a keen swimmer.</p>
                      <p className="text-left"><b>- Director, Constructions</b></p>
                    </div>
                  </div>
                </div>
              </div>
              <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                {/* <span className="carousel-control-prev-icon c-prv ar-l" aria-hidden="true"></span> */}
                <span className="sr-only">Previous</span>  
              </a>
              <img className="ap top1 lr-a" src="../Images/ap.png"/>
              <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                {/* <span className="carousel-control-next-icon c-nxt ar-r" aria-hidden="true"></span> */}
                <span className="sr-only">Next</span>
              </a>
              <img className="an top1 rr-a" src="../Images/an.png"/>
            </div>
          </div>
        </div>
      </div>
	  <script src="https://www.kenyt.ai/botapp/ChatbotUI/dist/js/bot-loader.js" type="text/javascript" data-bot="25390341"></script>
      {/* <div className="aspire-red pt-5 pb-5 pl-5 pr-5">
        <div className="container">
          <div className="row">
            <div className="col-md-3 circle-mob">
              <span className="circle-text-span">Ready to Move <p className="circle-text-1">1</p></span>
            </div>
            <div className="col-md-3 circle-mob">
              <span className="circle-text-span">Ongoing <p className="circle-text-2">6+</p></span>
            </div>
            <div className="col-md-3 circle-mob">
              <span className="circle-text-span">Completed <p className="circle-text-3">39+</p></span>
            </div>
            <div className="col-md-3 circle-mob">
              <span className="circle-text-span">Commercial <p className="circle-text-1">4</p></span>
            </div>
          </div>
        </div>
      </div> */}
    </Layout>
  )
}

export default aboutPage
